import { sm } from "./mediaQuery"

export const typography = `
    ${sm} {
        h1.ant-typography {
            font-size: 30px;    
        }    
    }
    
    /* this doesnt work, so .. */
    /* @heading-3-size: ceil(@font-size-base * 1.41); */
    h3.ant-typography {
        font-size: 18px;
    }
`
