"use client"

import styled from "@emotion/styled"
import { Typography } from "antd"
import CookieConsent from "react-cookie-consent"
import { darken, lighten } from "polished"
import { useEffect, useState } from "react"
import { getLocalStorage, setLocalStorage } from "../lib/gaTracking/storageHelper"
import { theme } from "../styles/styles"
import { sm } from "../styles/mediaQuery"

export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState(false)

  useEffect(() => {
    const storedCookieConsent = getLocalStorage("cookie_consent", null)

    setCookieConsent(storedCookieConsent)
  }, [setCookieConsent])

  useEffect(() => {
    if (cookieConsent === false) {
      window.gtag("consent", "update", {
        ad_storage: "denied",
        analytics_storage: "denied",
        // @ts-ignore
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted"
      })
    }

    if (cookieConsent === true) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
        // @ts-ignore
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted"
      })
    }

    setLocalStorage("cookie_consent", cookieConsent)
  }, [cookieConsent])

  return (
    <CookieDialogWrapper>
      <CookieConsent
        visible={cookieConsent != null ? "hidden" : "show"}
        location="bottom"
        enableDeclineButton
        disableStyles={true}
        overlay={true}
        declineButtonText="Zamítnout"
        ariaDeclineLabel="Zamítnout"
        buttonText="Povolit vše"
        ariaAcceptLabel="Povolit vše"
        buttonClasses="ant-btn"
        declineButtonClasses="ant-btn"
        buttonWrapperClasses="btn-wrapper"
        containerClasses="cookies-dialog-wrapper"
        onAccept={() => setCookieConsent(true)}
        onDecline={() => setCookieConsent(false)}>
        <FirstRow>
          <Typography.Title level={3}>Záleží nám na vašem soukromí</Typography.Title>
        </FirstRow>
        <Typography.Paragraph>Při stisknutí &quot;Povolit vše&quot; povolíte cookies pro lepší navigaci a analýzu na našem webu.</Typography.Paragraph>
      </CookieConsent>
    </CookieDialogWrapper>
  )
}

const CookieDialogWrapper = styled.div`
  .cookies-dialog-wrapper {
    display: flex;
    position: fixed;
    z-index: 900;
    width: 50vw;
    min-width: 300px;
    max-width: 500px;
    min-height: 12vh;
    padding: 20px;
    border-radius: 20px;
    bottom: 40px;
    left: 50%;
    background-color: ${theme.colors["main-color-blue"]};
    transform: translate(-50%, -40px);
    flex-direction: column;
    justify-content: center;
    animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    box-shadow: ${theme.boxShadow.default};

    ${sm} {
      min-width: initial;
      max-width: initial;
      width: calc(100% - 30px);
      margin-left: 15px;
      padding: 14px;
      left: initial;
      transform: translate(0, -15px);
    }

    .btn-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      > * {
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .ant-typography {
      color: white;
    }

    ${sm} {
      div.ant-typography {
        font-size: 12px;
      }
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      color: #fff;
      border-color: ${lighten(0.1, theme.colors["main-color-blue"])};
      background: ${lighten(0.1, theme.colors["main-color-blue"])};
    }

    .ant-btn-primary:active {
      color: #fff;
      border-color: ${darken(0.1, theme.colors["main-color-blue"])};
      background: ${darken(0.1, theme.colors["main-color-blue"])};
    }
  }
`

const FirstRow = styled.div``
