import React from "react"
import { useSession } from "next-auth/react"
import { Spin } from "antd"
import styled from "@emotion/styled"

// https://next-auth.js.org/getting-started/client
export const Auth = ({ children }: Props) => {
  const { data: session } = useSession({ required: true })
  const isUser = !!session?.user

  if (isUser) {
    return <>{children}</>
  }

  return <LoadingAtCenter />
}

const LoadingAtCenter = styled(Spin)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
`

interface Props {
  children: React.ReactNode
}
