import React, { createContext, useContext, useEffect, useState, useCallback } from "react"
import { CartContextType, CartItem } from "./Cart"
import { notification } from "antd"

const CartContext = createContext<CartContextType | undefined>(undefined)

export const SHIPPING_ITEM: CartItem = {
  id: "shipping",
  name: "Doprava",
  price: 100,
  quantity: 1
}

const useCart = (): CartContextType => {
  const context = useContext(CartContext)
  if (!context) {
    throw new Error("useCart must be used within a CartProvider")
  }
  return context
}

const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>([])
  const [shipping, setShipping] = useState<CartItem | null>(null)
  const [shippingRemovedManually, setShippingRemovedManually] = useState(false)

  useEffect(() => {
    const savedCart = localStorage.getItem("cart")
    const savedShipping = localStorage.getItem("shipping")
    const savedShippingRemovedManually = localStorage.getItem("shippingRemovedManually")

    if (savedCart) setCart(JSON.parse(savedCart))
    if (savedShipping) setShipping(JSON.parse(savedShipping))
    if (savedShippingRemovedManually) setShippingRemovedManually(JSON.parse(savedShippingRemovedManually))
  }, [])

  const removeShippingIfCartEmpty = useCallback(() => {
    if (cart.length === 0) {
      setShipping(null)
      setShippingRemovedManually(false)
    }
  }, [cart])

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart))
    removeShippingIfCartEmpty()
    if (cart.length > 0 && !shipping && !shippingRemovedManually) {
      setShipping(SHIPPING_ITEM)
    }
  }, [cart, removeShippingIfCartEmpty, shipping, shippingRemovedManually])

  useEffect(() => {
    if (shipping) localStorage.setItem("shipping", JSON.stringify(shipping))
  }, [shipping])

  useEffect(() => {
    localStorage.setItem("shippingRemovedManually", JSON.stringify(shippingRemovedManually))
  }, [shippingRemovedManually])

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((cartItem) => cartItem.id === item.id)
      const updatedCart = existingItem
        ? prevCart.map((cartItem) => (cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem))
        : [...prevCart, { ...item, quantity: 1 }]

      if (updatedCart.length > 0 && !shipping && !shippingRemovedManually) {
        setShipping(SHIPPING_ITEM)
      }

      return updatedCart
    })
    notification.success({ message: "Zboží bylo přidáno do košíku" })
  }

  const removeFromCart = (id: string) => {
    setCart((prevCart) =>
      prevCart
        .map((item) => {
          if (item.id === id) {
            if (item.quantity > 1) {
              return { ...item, quantity: item.quantity - 1 }
            } else {
              return null
            }
          }
          return item
        })
        .filter((item) => item !== null)
    )
  }

  const removeShipping = () => {
    setShipping(null)
    setShippingRemovedManually(true)
    notification.success({ message: "Doprava byla odebrána" })
  }

  const clearCart = () => {
    setCart([])
    setShipping(null)
    setShippingRemovedManually(false)
  }

  const getTotalPrice = () => {
    const itemsTotal = cart.reduce((total, item) => total + item.price * item.quantity, 0)
    const shippingCost = shipping ? shipping.price : 0
    return itemsTotal + shippingCost
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        getTotalPrice,
        addShipping: setShipping,
        removeShipping,
        shipping
      }}>
      {children}
    </CartContext.Provider>
  )
}

export { CartProvider, useCart }
