import { NextSeoProps } from "next-seo"
import { webUrl } from "./config/web"

export const fbAppId = "2292387061015118"

const defaultSeo: NextSeoProps = {
  titleTemplate: `%s | Vzdělání budoucnosti`,
  canonical: webUrl,
  openGraph: {
    type: "website",
    locale: "cs_CZ",
    site_name: "Vzdělání budoucnosti",
    images: [
      {
        url: `${webUrl}/images/01_2022_vzdelani_budoucnosti_web_header_1920_1.jpg`,
        width: 1920,
        height: 1080,
        alt: "01_2022_vzdelani_budoucnosti_web_header_1920_1"
      }
    ]
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image"
  },
  facebook: {
    appId: fbAppId
  }
}

export default defaultSeo
